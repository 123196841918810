import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { styled } from '@mui/material/styles'
import {
  AttentionItemColorPaidIcon,
  AttentionItemColorRejectedIcon,
} from 'settle-ui/components/Attention/Attention.tokens'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

export const CheckPassedIcon = styled(CheckCircleIcon)({
  color: AttentionItemColorPaidIcon,
  fontSize: '15px',
})
export const CircleIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
  color: theme.tokens.ThemeColorCommonOnSurfaceTertiary,
  fontSize: '15px',
}))
export const ErrorIcon = styled(CancelIcon)({
  color: AttentionItemColorRejectedIcon,
  fontSize: '15px',
})

interface ICheckProps {
  error: boolean
}
export const Check = styled('div', {
  shouldForwardProp: youShallNotPass('error'),
})<ICheckProps>(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(0.75),
  ...(error && {
    color: AttentionItemColorRejectedIcon,
  }),
  '&:last-child': {
    marginBottom: 0,
  },
}))
