import { InputAdornment } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IconButton } from 'settle-ui/components/IconButton/IconButton'

export const AutocompleteInputAdornment = styled(InputAdornment)({
  position: 'absolute',
  right: 6,
})

export const EditButton = styled(IconButton)(() => ({
  display: 'none',
}))
