import { useState, type FC } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { TextField, type ITextFieldProps } from '../TextField/TextField'
import { PasswordRequirements } from './component/PasswordRequirements/PasswordRequirements'
import * as $ from './PasswordInput.styled'

export interface IPasswordInputProps extends ITextFieldProps {
  error?: boolean
  helperText?: string | string[]
  'data-test-id'?: string
  showPasswordRequirements?: boolean
}

export const PasswordInput: FC<IPasswordInputProps> = ({
  size,
  error,
  color,
  margin,
  helperText,
  'data-test-id': dataTestId = 'password-input',
  showPasswordRequirements = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [value, setValue] = useState<Maybe<string>>(null)
  const [isFocused, setIsFocused] = useState(false)
  const isRequirementsVisible = Boolean(showPasswordRequirements && (isFocused || value || error))

  return (
    <div>
      <TextField
        {...props}
        data-test-id={dataTestId}
        size={size}
        margin={margin}
        fullWidth={true}
        color={color}
        variant="outlined"
        required={props.required}
        label={props.label}
        error={error}
        onChange={(e) => {
          props?.onChange?.(e)
          setValue(e.target.value)
        }}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        autoComplete="true"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <$.Button
                size="small"
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </$.Button>
            </InputAdornment>
          ),
          sx: {
            paddingRight: 0,
          },
        }}
        helperText={helperText}
      />
      {isRequirementsVisible && <PasswordRequirements value={value} error={Boolean(error)} />}
    </div>
  )
}
